import {collection, doc, getDoc, getDocs, query, setDoc, where,} from "firebase/firestore"; // Firestore Client SDK methods
import {Bookings} from "../lib/classes/bookings-class"; // Adjust the path to your model
import {db} from "../lib/connect-firebase"; // Ensure this points to Firestore Client SDK instance
import {BookingDataType} from "@/types/bookings_type";

class BookingDetailsFirebaseImpl {
  // Using Firestore Client SDK collection reference
  private _bookings = collection(db, "bookings"); // No change to this line

  async getBlockedDatesForVillaId(villaId: string): Promise<Date[]>{
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_SAVINGZ_GPT_SERVER}/blocked-dates/${villaId}`
    );
    const data = await res.json();
    return  data
        ? data.dates.map((dateString: string) => new Date(dateString))
        : [];
  }
  // Save booking data to Firestore
  async saveBookingData(bookingData: Bookings) {
    console.log(`User info added is ${JSON.stringify(bookingData.toJson())}`);

    try {
      // Get document reference with the provided booking ID
      const docRef = doc(this._bookings, bookingData.id); // Firestore Client SDK method
      await setDoc(docRef, bookingData.toJson()); // Use setDoc() from Firestore Client SDK
      console.log(`User information added to Firestore with ID: ${docRef.id}`);
      return {success: true};
    } catch (error) {
      console.error(`Error adding user information: ${error}`);
    }
  }


  async fetchBookingsBasedOnUser({
                                   phoneNumber,
                                 }: {
    phoneNumber: string;
  }): Promise<BookingDataType[] | null> {
    try {
      // Create a query to fetch bookings where phoneNumber matches the provided value
      const q = query(this._bookings, where("number", "==", phoneNumber));

      // Execute the query and get the matching documents
      const querySnapshot = await getDocs(q);

      // Extract and return the booking data
      const bookings = querySnapshot.docs.map((doc) => ({
        // ...doc.data(),
        bookingId: doc.data().id,
        propertyId: doc.data().propertyId,
        purpose: doc.data().purpose,
        bookedRooms: doc.data().bookedRooms,
        email: doc.data().email,
        checkInDate: doc.data().checkInDate,
        checkOutDate: doc.data().checkOutDate,
        name: doc.data().name,
        number: doc.data().number,
        noPeople: doc.data().noPeople,
        status: doc.data().status,
        paymentId: doc.data().paymentId,
      }));

      return bookings; // Return the array of bookings
    } catch (error) {
      console.error(`Error fetching bookings: ${error}`);
      return null;
    }
  }

  async fetchBookingBasedOnId({
                                bookingId,
                                phoneNumber,
                              }: {
    bookingId: string;
    phoneNumber: string;
  }): Promise<BookingDataType | null> {
    try {
      // Get a reference to the booking document using the bookingId
      const docRef = doc(this._bookings, bookingId); // Firestore Client SDK method

      // Fetch the document from Firestore
      const docSnap = await getDoc(docRef);

      // Check if the document exists and extract the data
      if (docSnap.exists()) {
        const data = docSnap.data();

        // Validate that the phone number matches the one in the document
        if (data.number === phoneNumber) {
          const bookingData = {
            bookingId: data.id,
            propertyId: data.propertyId,
            purpose: data.purpose,
            bookedRooms: data.bookedRooms,
            email: data.email,
            checkInDate: data.checkInDate,
            checkOutDate: data.checkOutDate,
            name: data.name,
            number: data.number,
            noPeople: data.noPeople,
            status: data.status,
            paymentId: data.paymentId,
          };

          return bookingData; // Return the booking data if phoneNumber matches
        } else {
          console.log(
              `Phone number does not match for booking ID: ${bookingId}`
          );
          return null; // Return null if phone number doesn't match
        }
      } else {
        console.log(`No booking found with ID: ${bookingId}`);
        return null; // Return null if no document found
      }
    } catch (error) {
      console.error(`Error fetching booking with ID: ${bookingId}`, error);
      return null;
    }
  }
}

export default BookingDetailsFirebaseImpl;
